import api from "@/services/api";
export default {
  getChartValues(data) {
    return api().post("Report/Chart", data);
  },
  getReportLinks(data) {
    return api().post("Report/GetCampaignReportLinks", data);
  },
  getUnassignedReportLinks(data) {
    return api().get("Report/GetUnassignedReports", data);
  },
  addCampaignReportLink(data) {
    return api().post("Report/AddCampaignReportLink", data);
  },
  removeCampaignReportLink(data) {
    return api().post("Report/RemoveCampaignReportLink", data);
  },
  getLiveCampaignAnalytics (id) {
      return api().get('Report/GetLiveDashboard?campaignId=' + id)
  },
  uploadReport(data) {
    return api().post("Report/UploadStats", data);
  },
  uploadReportJob(data) {
    return api().post("Report/UploadReportJob", data);
  },
  getReportJobProgress(userId, topXRows) {
    return api().get('Report/GetReportJobProgress?userId=' + userId + '&topXRows=' + topXRows);
  },
  getDashboardReportDownload(campaignId, routeRequestId) {
    return api().get('Report/GetDashboardReportDownload?campaignId=' + campaignId + "&routeRequestId=" + routeRequestId, { responseType: "blob" })
  },
  getPCAReportDownload(campaignId, routeRequestId) {
    return api().get('Report/GetPCAReportDownload?campaignId=' + campaignId + "&routeRequestId="+ routeRequestId, { responseType: "blob" })
  },
  GetPostCampaignAnalysisPCAReportDownload(campaignId, routeRequestId) {
    return api().get('Report/GetPostCampaignAnalysisPCAReportDownload?campaignId=' + campaignId + "&routeRequestId="+ routeRequestId, { responseType: "blob" })
  },
  reportGeneration(campaignId, campaignBurstId, campaignBurstScreenId, exportText, exportOverlays, dayPart, startDate, endDate, startTime, endTime) {
    return api().get('Report/GetReportDownload?campaignId='
      +campaignId+'&campaignBurstId='
      +campaignBurstId+'&campaignBurstScreenId='
      +campaignBurstScreenId+'&exportText='
      +exportText+'&exportOverlays='
      +exportOverlays+'&dayPart='
      +dayPart+'&startDate='
      +startDate+'&endDate='
      +endDate+'&startHour='
      +startTime+'&endHour='
      +endTime)
  },
  getPlayoutReportTables(searchString) //playoutReportGeneration
  {
    return api().get('Report/getPlayoutReportTables?searchString='+searchString)
  },
  getPlayoutReportExpanded(searchString, campaignId, granularity)
  {
    return api().get('Report/GetPlayoutReportExpanded?searchString='+searchString+"&campaignId="+campaignId+"&granularity="+granularity)
  },
  getPlayoutReportExport(searchString, campaignId, granularity)
  {
    return api().get('Report/GetPlayoutReportExport?searchString='+searchString+"&campaignId="+campaignId+"&granularity="+granularity)
  },
  getPlayoutReportNormalized(searchString, campaignId, granularity)
  {
    return api().get('Report/GetPlayoutReportNormalized?searchString='+searchString+"&campaignId="+campaignId+"&granularity="+granularity)
  },
  setReportProcessPriority(data) {
    return api().post("Report/SetReportProcessPriority", data);
  },
  getReportEmailDigest(dateStart, dateEnd, deliveryTypes) {
    return api().get("Report/GetReportEmailDigest?dateStart="+dateStart+"&dateEnd="+dateEnd+"&deliveryTypes="+deliveryTypes);
  },
  getPlayoutDashboardStats(campaignId) {
    return api().get("Report/GetPlayoutDashboardStats?campaignId="+campaignId);
  },
  getPlayoutReportDirectExport(granularity, whereParams, customFileName) {
    const encodedGranularity = encodeURIComponent(granularity);
    const encodedwhereParams = encodeURIComponent(whereParams);
    const encodedFileName = encodeURIComponent(customFileName);

    // Build the complete URL
    const url = `Report/GetPlayoutReportDirectExport?granularity=${encodedGranularity}&whereParams=${encodedwhereParams}&customFileName=${encodedFileName}`;

    return api().get(url);
  },
  getDashboardStatMetricTypes() {
    return api().get("Report/GetDashboardStatMetricTypes");
  }
};
